@import "../../variables.scss";

.home {
  composes: page from "../../style/index.scss";
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  position: relative;
  padding: 0 !important;

  &::before {
    content: "";
    height: 63px;
    background-image: linear-gradient(to top right, rgba(17, 16, 16, 0), rgba(10, 10, 10, .5) 40%, rgba(4, 3, 3, .7));
    width: 60px;
    margin-left: auto;
    border-bottom-left-radius: 40px;
  }
}

.container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  justify-content: flex-end;
  margin-top: auto;
  padding: 60px $spacing-xs $spacing-xs $spacing-xs;
  background-image: linear-gradient(to bottom, rgba(17, 16, 16, 0), rgba(10, 10, 10, .5) 30%, rgba(4, 3, 3, .7));

  h1 {
    margin: 0;
    color: $white;
  }

  h3 {
    color: $white;
    margin: $spacing-xs 0 $spacing-sm;
  }

  button {
    box-shadow: 0 0 8px -2px white;
  }
}
