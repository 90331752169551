@import "../../variables.scss";

.userInfoModal {

  button[class*="close"] {
    box-shadow: none;
  }

  .header {
    font-weight: 400;
    font-size: 16px;
  }

  .form {
    display: flex;
    flex-direction: column;

    .formInputWrapper {
      margin: 20px 0;

      label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        input {
          background-color: #fff;
          border: 2px solid var(--active-color);
          line-height: 26px;
        }
      }

      > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        label {
          width: calc(50% - 8px);
        }
      }
    }

    .errorMessage {
      color: $danger-color;
      margin: 10px 0;
    }

    button {
      margin-top: 10px;
    }
  }
}
